import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { CheckDuplicate } from 'components/CheckDuplicate';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import FilesListPage from 'pages/files/List';
import OperatordevicesListPage from 'pages/operatordevices/List';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
const OperatorsEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		name: yup.string().required().label("Nome"),
		surname: yup.string().required().label("Cognome"),
		telephone: yup.string().required().label("Telefono"),
		status_operator: yup.string().required().label("Stato Operatore"),
		remuneration: yup.number().nullable().label("Compenso"),
		birth_date: yup.string().nullable().label("Data Compleanno"),
		birth_place: yup.string().nullable().label("Luogo Nascita"),
		residence_address: yup.string().nullable().label("Indirizzo Residenza"),
		residence_city: yup.string().nullable().label("Città Residenza"),
		residence_region: yup.string().nullable().label("Regione Residenza"),
		residence_cap: yup.string().nullable().label("CAP Residenza"),
		domicile_address: yup.string().nullable().label("Indirizzo Domicilio"),
		domicile_city: yup.string().nullable().label("Città Domicilio"),
		domicile_region: yup.string().nullable().label("Regione Domicilio"),
		domicile_cap: yup.string().nullable().label("Cap Domicilio"),
		document_type: yup.string().nullable().label("Tipo Documento"),
		document_number: yup.string().nullable().label("Numero Documento"),
		issued_by: yup.string().nullable().label("Rilasciato Da"),
		issued_date: yup.string().nullable().label("Data Rilascio"),
		expiry_date: yup.string().nullable().label("Data Scadenza"),
		work_number: yup.string().nullable().label("Numero Lavoro"),
		vat_number: yup.string().nullable().label("Partita IVA"),
		alias: yup.string().required().label("Alias"),
		qualification: yup.string().nullable().label("Qualifica"),
		current_job: yup.string().nullable().label("Lavoro Attuale"),
		switchboard_code: yup.string().nullable().label("Codice Reclutamento"),
		color: yup.string().nullable().label("Colore")
	});
	// form default values
	const formDefaultValues = {
		name: '', 
		surname: '', 
		telephone: '', 
		status_operator: '', 
		remuneration: '', 
		birth_date: new Date(), 
		birth_place: '', 
		residence_address: '', 
		residence_city: '', 
		residence_region: '', 
		residence_cap: '', 
		domicile_address: '', 
		domicile_city: '', 
		domicile_region: '', 
		domicile_cap: '', 
		document_type: '', 
		document_number: '', 
		issued_by: '', 
		issued_date: new Date(), 
		expiry_date: new Date(), 
		work_number: '', 
		vat_number: '', 
		alias: '', 
		qualification: '', 
		current_job: '', 
		switchboard_code: '', 
		color: "NULL", 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/operators`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="OperatorsEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="modificare"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={formData}
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);
                            }
                            }
                            >
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Nome *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <CheckDuplicate value={formik.values.name} apiPath="components_data/operators_name_exist">
                                                { (checker) => 
                                                <>
                                                <InputText name="name" onBlur={checker.check} onChange={formik.handleChange}  value={formik.values.name}   label="Nome" type="text" placeholder="accedere Nome"        className={inputClassName(formik?.errors?.name)} />
                                                <ErrorMessage name="name" component="span" className="p-error" />
                                                {(!checker.loading && checker.exist) && <small className="p-error">Non disponibile</small>}
                                                {checker.loading && <small className="text-500">Checking...</small> }
                                                </>
                                                }
                                                </CheckDuplicate>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Cognome *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="surname"  onChange={formik.handleChange}  value={formik.values.surname}   label="Cognome" type="text" placeholder="accedere Cognome"        className={inputClassName(formik?.errors?.surname)} />
                                                <ErrorMessage name="surname" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Telefono *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="telephone"  onChange={formik.handleChange}  value={formik.values.telephone}   label="Telefono" type="text" placeholder="accedere Telefono"        className={inputClassName(formik?.errors?.telephone)} />
                                                <ErrorMessage name="telephone" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Stato Operatore *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource   apiPath="components_data/status_operator_option_list"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <Dropdown  name="status_operator"     optionLabel="label" optionValue="value" value={formik.values.status_operator} onChange={formik.handleChange} options={response} label="Stato Operatore"  placeholder="Seleziona un valore ..."  className={inputClassName(formik?.errors?.status_operator)}   />
                                                    <ErrorMessage name="status_operator" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Compenso 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="remuneration"  onChange={formik.handleChange}  value={formik.values.remuneration}   label="Compenso" type="number" placeholder="accedere Compenso"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.remuneration)} />
                                                <ErrorMessage name="remuneration" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Data Compleanno 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="birth_date" showButtonBar className={inputClassName(formik?.errors?.birth_date)} dateFormat="yy-mm-dd" value={formik.values.birth_date} onChange={formik.handleChange} showIcon        />
                                                <ErrorMessage name="birth_date" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Luogo Nascita 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="birth_place"  onChange={formik.handleChange}  value={formik.values.birth_place}   label="Luogo Nascita" type="text" placeholder="accedere Luogo Nascita"        className={inputClassName(formik?.errors?.birth_place)} />
                                                <ErrorMessage name="birth_place" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Indirizzo Residenza 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="residence_address"  onChange={formik.handleChange}  value={formik.values.residence_address}   label="Indirizzo Residenza" type="text" placeholder="accedere Indirizzo Residenza"        className={inputClassName(formik?.errors?.residence_address)} />
                                                <ErrorMessage name="residence_address" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Città Residenza 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="residence_city"  onChange={formik.handleChange}  value={formik.values.residence_city}   label="Città Residenza" type="text" placeholder="accedere Città Residenza"        className={inputClassName(formik?.errors?.residence_city)} />
                                                <ErrorMessage name="residence_city" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Regione Residenza 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="residence_region"  onChange={formik.handleChange}  value={formik.values.residence_region}   label="Regione Residenza" type="text" placeholder="accedere Regione Residenza"        className={inputClassName(formik?.errors?.residence_region)} />
                                                <ErrorMessage name="residence_region" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                CAP Residenza 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="residence_cap"  onChange={formik.handleChange}  value={formik.values.residence_cap}   label="CAP Residenza" type="text" placeholder="accedere CAP Residenza"        className={inputClassName(formik?.errors?.residence_cap)} />
                                                <ErrorMessage name="residence_cap" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Indirizzo Domicilio 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="domicile_address"  onChange={formik.handleChange}  value={formik.values.domicile_address}   label="Indirizzo Domicilio" type="text" placeholder="accedere Indirizzo Domicilio"        className={inputClassName(formik?.errors?.domicile_address)} />
                                                <ErrorMessage name="domicile_address" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Città Domicilio 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="domicile_city"  onChange={formik.handleChange}  value={formik.values.domicile_city}   label="Città Domicilio" type="text" placeholder="accedere Città Domicilio"        className={inputClassName(formik?.errors?.domicile_city)} />
                                                <ErrorMessage name="domicile_city" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Regione Domicilio 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="domicile_region"  onChange={formik.handleChange}  value={formik.values.domicile_region}   label="Regione Domicilio" type="text" placeholder="accedere Regione Domicilio"        className={inputClassName(formik?.errors?.domicile_region)} />
                                                <ErrorMessage name="domicile_region" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Cap Domicilio 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="domicile_cap"  onChange={formik.handleChange}  value={formik.values.domicile_cap}   label="Cap Domicilio" type="text" placeholder="accedere Cap Domicilio"        className={inputClassName(formik?.errors?.domicile_cap)} />
                                                <ErrorMessage name="domicile_cap" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Tipo Documento 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource   apiPath="components_data/document_type_option_list"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <Dropdown  name="document_type"     optionLabel="label" optionValue="value" value={formik.values.document_type} onChange={formik.handleChange} options={response} label="Tipo Documento"  placeholder="Seleziona un valore ..."  className={inputClassName(formik?.errors?.document_type)}   />
                                                    <ErrorMessage name="document_type" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Numero Documento 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="document_number"  onChange={formik.handleChange}  value={formik.values.document_number}   label="Numero Documento" type="text" placeholder="accedere Numero Documento"        className={inputClassName(formik?.errors?.document_number)} />
                                                <ErrorMessage name="document_number" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Rilasciato Da 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="issued_by"  onChange={formik.handleChange}  value={formik.values.issued_by}   label="Rilasciato Da" type="text" placeholder="accedere Rilasciato Da"        className={inputClassName(formik?.errors?.issued_by)} />
                                                <ErrorMessage name="issued_by" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Data Rilascio 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="issued_date" showButtonBar className={inputClassName(formik?.errors?.issued_date)} dateFormat="yy-mm-dd" value={formik.values.issued_date} onChange={formik.handleChange} showIcon        />
                                                <ErrorMessage name="issued_date" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Data Scadenza 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="expiry_date" showButtonBar className={inputClassName(formik?.errors?.expiry_date)} dateFormat="yy-mm-dd" value={formik.values.expiry_date} onChange={formik.handleChange} showIcon        />
                                                <ErrorMessage name="expiry_date" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Numero Lavoro 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="work_number"  onChange={formik.handleChange}  value={formik.values.work_number}   label="Numero Lavoro" type="text" placeholder="accedere Numero Lavoro"        className={inputClassName(formik?.errors?.work_number)} />
                                                <ErrorMessage name="work_number" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Partita IVA 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="vat_number"  onChange={formik.handleChange}  value={formik.values.vat_number}   label="Partita IVA" type="text" placeholder="accedere Partita IVA"        className={inputClassName(formik?.errors?.vat_number)} />
                                                <ErrorMessage name="vat_number" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Alias *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource   apiPath="components_data/alias_option_list"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <Dropdown  name="alias"     optionLabel="label" optionValue="value" value={formik.values.alias} onChange={formik.handleChange} options={response} label="Alias"  placeholder="Seleziona un valore ..."  className={inputClassName(formik?.errors?.alias)}   />
                                                    <ErrorMessage name="alias" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Qualifica 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="qualification"  onChange={formik.handleChange}  value={formik.values.qualification}   label="Qualifica" type="text" placeholder="accedere Qualifica"        className={inputClassName(formik?.errors?.qualification)} />
                                                <ErrorMessage name="qualification" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Lavoro Attuale 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="current_job"  onChange={formik.handleChange}  value={formik.values.current_job}   label="Lavoro Attuale" type="text" placeholder="accedere Lavoro Attuale"        className={inputClassName(formik?.errors?.current_job)} />
                                                <ErrorMessage name="current_job" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Codice Reclutamento 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="switchboard_code"  onChange={formik.handleChange}  value={formik.values.switchboard_code}   label="Codice Reclutamento" type="text" placeholder="accedere Codice Reclutamento"        className={inputClassName(formik?.errors?.switchboard_code)} />
                                                <ErrorMessage name="switchboard_code" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Colore 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="color"  onChange={formik.handleChange}  value={formik.values.color}   label="Colore" type="text" placeholder="accedere Colore"        className={inputClassName(formik?.errors?.color)} />
                                                <ErrorMessage name="color" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Aggiornare" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="page-section mb-3" >
            <div className="container">
                <div className="grid ">
                    <div className="md:col-9 sm:col-12 comp-grid" >
                        <div className="card ">
                            <div className="">
                                { pageReady &&
                                <OperatordevicesListPage isSubPage  limit="10" fieldName="operators.id_operator" fieldValue={formData.id_operator} showHeader={true} showBreadcrumbs={true} showFooter={true} paginate={true} >
                                </OperatordevicesListPage>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="md:col-9 sm:col-12 comp-grid" >
                        <div className="card ">
                            <div className="">
                                { pageReady &&
                                <FilesListPage isSubPage  limit="10" fieldName="files.id_operator" fieldValue={formData.id_operator} showHeader={true} showBreadcrumbs={true} showFooter={true} paginate={true} >
                                </FilesListPage>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
OperatorsEditPage.defaultProps = {
	primaryKey: 'id_operator',
	pageName: 'operators',
	apiPath: 'operators/edit',
	routeName: 'operatorsedit',
	submitButtonLabel: "Aggiornare",
	formValidationError: "Il modulo non è valido",
	formValidationMsg: "Si prega di compilare il modulo",
	msgTitle: "Aggiorna registro",
	msgAfterSave: "Registra aggiornato correttamente",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default OperatorsEditPage;
