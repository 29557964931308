import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Menubar } from 'primereact/menubar';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import AppointmentsEditPage from 'pages/appointments/Edit';
import useApp from 'hooks/useApp';
import useAuth from 'hooks/useAuth';
import useUtils from 'hooks/useUtils';

import useViewPage from 'hooks/useViewPage';
const AppointmentsViewPage = (props) => {
		const auth = useAuth();
	const app = useApp();
	const utils = useUtils();
	const pageController = useViewPage(props);
	const { item, pageReady, loading, apiRequestError, deleteItem } = pageController;
	function ActionButton(data){
		const items = [
		{
			label: "Edit",
			command: (event) => { app.openPageDialog(<AppointmentsEditPage isSubPage apiPath={`/appointments/edit/${data.id_appointment}`} />, {closeBtn: true }) },
			icon: "pi pi-pencil",
			visible: () => auth.canView('appointments/edit')
		},
		{
			label: "Delete",
			command: (event) => { deleteItem(data.id_appointment) },
			icon: "pi pi-trash",
			visible: () => auth.canView('appointments/delete')
		}
	]
	.filter((item) => {
		if(item.visible){
			return item.visible()
		}
		return true;
	});
		return (<Menubar className="p-0 " model={items} />);
	}
	function PageFooter() {
		if (props.showFooter) {
			return (
				<div className="flex justify-content-between">
	<div className="flex justify-content-start">
	{ActionButton(item)}
	</div>
				</div>
			);
		}
	}
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	if(pageReady){
		return (
			<div>
<main id="AppointmentsViewPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="vista"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="col comp-grid" >
                    <div >
                        {/*PageComponentStart*/}
                        <div className="mb-3 grid ">
                            <div className="col-12 md:col-4">
                                <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                    <div className="">
                                        <div className="text-400 font-medium mb-1">Inzio</div>
                                        <div className="font-bold">{utils.humanDate( item.start_time )}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                    <div className="col-fixed">
                                        <Avatar size="large" icon="Fine" />
                                        </div>
                                        <div className="">
                                            <div className="text-400 font-medium mb-1">End Time</div>
                                            <div className="font-bold">{utils.humanDate( item.end_time )}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 md:col-4">
                                    <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                        <div className="">
                                            <div className="text-400 font-medium mb-1">Minuti Chiamata</div>
                                            <div className="font-bold">{ item.minutes_call }</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 md:col-4">
                                    <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                        <div className="">
                                            <div className="text-400 font-medium mb-1">Titolo Appuntamento</div>
                                            <div className="font-bold">{ item.appointment_title }</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 md:col-4">
                                    <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                        <div className="">
                                            <div className="text-400 font-medium mb-1">Operatore Nome</div>
                                            <div className="font-bold">{ item.operators_name }</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 md:col-4">
                                    <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                        <div className="">
                                            <div className="text-400 font-medium mb-1">Operatore Cognome</div>
                                            <div className="font-bold">{ item.operators_surname }</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 md:col-4">
                                    <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                        <div className="">
                                            <div className="text-400 font-medium mb-1">Nome</div>
                                            <div className="font-bold">{ item.clients_name }</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 md:col-4">
                                    <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                        <div className="">
                                            <div className="text-400 font-medium mb-1">Cognome</div>
                                            <div className="font-bold">{ item.clients_surname }</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 md:col-4">
                                    <div className="card flex gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                        <div className="">
                                            <div className="text-400 font-medium mb-1">Bonus</div>
                                            <div className="font-bold">{ item.bonus_name }</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*PageComponentEnd*/}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
				<PageFooter />
			</div>
		);
	}
}
AppointmentsViewPage.defaultProps = {
	id: null,
	primaryKey: 'id_appointment',
	pageName: 'appointments',
	apiPath: 'appointments/view',
	routeName: 'appointmentsview',
	msgBeforeDelete: "Sei sicuro di voler cancellare questo record?",
	msgTitle: "Elimina record",
	msgAfterDelete: "Record cancellato con successo",
	showHeader: true,
	showFooter: true,
	exportButton: true,
	isSubPage: false,
}
export default AppointmentsViewPage;
