import { Routes, Route, Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

import IndexLayout from 'layouts/IndexLayout';
import MainLayout from 'layouts/MainLayout';
import AuthRoutes from 'components/AuthRoutes';
import IndexPage from 'pages/index/IndexPage';
import AliasList from 'pages/alias/List';
import AliasAdd from 'pages/alias/Add';
import AliasEdit from 'pages/alias/Edit';
import AppointmentsView from 'pages/appointments/View';
import AppointmentsAdd from 'pages/appointments/Add';
import AppointmentsEdit from 'pages/appointments/Edit';
import AppointmentsAgenda from 'pages/appointments/Agenda';
import AppointmentsScheduler from 'pages/appointments/Scheduler';
import AppointmentsAgendareclutamento from 'pages/appointments/Agendareclutamento';
import AppointmentsoperatorsView from 'pages/appointmentsoperators/View';
import AppointmentsoperatorsAdd from 'pages/appointmentsoperators/Add';
import AppointmentsoperatorsEdit from 'pages/appointmentsoperators/Edit';
import BonusList from 'pages/bonus/List';
import BonusView from 'pages/bonus/View';
import BonusAdd from 'pages/bonus/Add';
import BonusEdit from 'pages/bonus/Edit';
import ClientsList from 'pages/clients/List';
import ClientsView from 'pages/clients/View';
import ClientsAdd from 'pages/clients/Add';
import ClientsEdit from 'pages/clients/Edit';
import DaysList from 'pages/days/List';
import DaysView from 'pages/days/View';
import DaysAdd from 'pages/days/Add';
import DaysEdit from 'pages/days/Edit';
import DaysoperatorsList from 'pages/daysoperators/List';
import DaysoperatorsView from 'pages/daysoperators/View';
import DaysoperatorsAdd from 'pages/daysoperators/Add';
import DaysoperatorsEdit from 'pages/daysoperators/Edit';
import DevicesList from 'pages/devices/List';
import DevicesAdd from 'pages/devices/Add';
import DevicesEdit from 'pages/devices/Edit';
import FeedbackList from 'pages/feedback/List';
import FeedbackView from 'pages/feedback/View';
import FeedbackAdd from 'pages/feedback/Add';
import FeedbackEdit from 'pages/feedback/Edit';
import FilesList from 'pages/files/List';
import FilesView from 'pages/files/View';
import FilesAdd from 'pages/files/Add';
import FilesEdit from 'pages/files/Edit';
import FiletypesList from 'pages/filetypes/List';
import FiletypesAdd from 'pages/filetypes/Add';
import FiletypesEdit from 'pages/filetypes/Edit';
import GuidesList from 'pages/guides/List';
import GuidesView from 'pages/guides/View';
import GuidesAdd from 'pages/guides/Add';
import GuidesEdit from 'pages/guides/Edit';
import HoursList from 'pages/hours/List';
import HoursView from 'pages/hours/View';
import HoursAdd from 'pages/hours/Add';
import HoursEdit from 'pages/hours/Edit';
import HoursoperatorsList from 'pages/hoursoperators/List';
import HoursoperatorsView from 'pages/hoursoperators/View';
import HoursoperatorsAdd from 'pages/hoursoperators/Add';
import HoursoperatorsEdit from 'pages/hoursoperators/Edit';
import NotesList from 'pages/notes/List';
import NotesAdd from 'pages/notes/Add';
import NotesEdit from 'pages/notes/Edit';
import OperatordevicesList from 'pages/operatordevices/List';
import OperatordevicesAdd from 'pages/operatordevices/Add';
import OperatordevicesEdit from 'pages/operatordevices/Edit';
import OperatordivinatorytoolsList from 'pages/operatordivinatorytools/List';
import OperatordivinatorytoolsView from 'pages/operatordivinatorytools/View';
import OperatordivinatorytoolsAdd from 'pages/operatordivinatorytools/Add';
import OperatordivinatorytoolsEdit from 'pages/operatordivinatorytools/Edit';
import OperatorsList from 'pages/operators/List';
import OperatorsView from 'pages/operators/View';
import OperatorsAdd from 'pages/operators/Add';
import OperatorsEdit from 'pages/operators/Edit';
import OperatorsReclutamento from 'pages/operators/Reclutamento';
import OperatorsEditc from 'pages/operators/Editc';
import OperatorsCollaboratori from 'pages/operators/Collaboratori';
import OperatorstatusesList from 'pages/operatorstatuses/List';
import OperatorstatusesAdd from 'pages/operatorstatuses/Add';
import OperatorstatusesEdit from 'pages/operatorstatuses/Edit';
import PermissionsList from 'pages/permissions/List';
import PermissionsView from 'pages/permissions/View';
import PermissionsAdd from 'pages/permissions/Add';
import PermissionsEdit from 'pages/permissions/Edit';
import QualificationsList from 'pages/qualifications/List';
import QualificationsView from 'pages/qualifications/View';
import QualificationsAdd from 'pages/qualifications/Add';
import QualificationsEdit from 'pages/qualifications/Edit';
import RolesList from 'pages/roles/List';
import RolesView from 'pages/roles/View';
import RolesAdd from 'pages/roles/Add';
import RolesEdit from 'pages/roles/Edit';
import SupportList from 'pages/support/List';
import SupportAdd from 'pages/support/Add';
import SupportEdit from 'pages/support/Edit';
import TypesdocumentsList from 'pages/typesdocuments/List';
import TypesdocumentsView from 'pages/typesdocuments/View';
import TypesdocumentsAdd from 'pages/typesdocuments/Add';
import TypesdocumentsEdit from 'pages/typesdocuments/Edit';
import AmministratorePage from 'pages/home/AmministratorePage';
import UtentePage from 'pages/home/UtentePage';
import AccountPages from 'pages/account';
import HomePage from './pages/home/HomePage';
import IndexPages from './pages/index';
import ErrorPages from './pages/errors';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/styles/layout.scss';
const App = () => {
	const auth = useAuth();
	function DefaultPage(){
		if(!auth.isLoggedIn){
			return <IndexPage />
		}
		return <Navigate to="/home" replace />;
	}
	function UserRoleHomePage(){
		const userRole = (auth.userRole || '').toLowerCase();
		switch(userRole){
			case 'amministratore':
				return <AmministratorePage />
			case 'utente':
				return <UtentePage />
			default:
				return <HomePage />;
		}
	}
	return (
		<Routes>
			<Route exact element={<AuthRoutes />}>
			<Route element={<MainLayout />}>
				<Route path="/home" element={<UserRoleHomePage />} />
				

				{/* alias pages routes */}
				<Route path="/alias" element={<AliasList />} />
				<Route path="/alias/:fieldName/:fieldValue" element={<AliasList />} />
				<Route path="/alias/index/:fieldName/:fieldValue" element={<AliasList />} />
				<Route path="/alias/add" element={<AliasAdd />} />
				<Route path="/alias/edit/:pageid" element={<AliasEdit />} />

				{/* appointments pages routes */}
				<Route path="/appointments/view/:pageid" element={<AppointmentsView />} />
				<Route path="/appointments/add" element={<AppointmentsAdd />} />
				<Route path="/appointments/edit/:pageid" element={<AppointmentsEdit />} />
				<Route path="/appointments/agenda" element={<AppointmentsAgenda />} />
				<Route path="/appointments/agenda/:fieldName/:fieldValue" element={<AppointmentsAgenda />} />
				<Route path="/appointments/scheduler" element={<AppointmentsScheduler />} />
				<Route path="/appointments/scheduler/:fieldName/:fieldValue" element={<AppointmentsScheduler />} />
				<Route path="/appointments/agendareclutamento" element={<AppointmentsAgendareclutamento />} />
				<Route path="/appointments/agendareclutamento/:fieldName/:fieldValue" element={<AppointmentsAgendareclutamento />} />

				{/* appointmentsoperators pages routes */}
				<Route path="/appointmentsoperators/view/:pageid" element={<AppointmentsoperatorsView />} />
				<Route path="/appointmentsoperators/add" element={<AppointmentsoperatorsAdd />} />
				<Route path="/appointmentsoperators/edit/:pageid" element={<AppointmentsoperatorsEdit />} />

				{/* bonus pages routes */}
				<Route path="/bonus" element={<BonusList />} />
				<Route path="/bonus/:fieldName/:fieldValue" element={<BonusList />} />
				<Route path="/bonus/index/:fieldName/:fieldValue" element={<BonusList />} />
				<Route path="/bonus/view/:pageid" element={<BonusView />} />
				<Route path="/bonus/add" element={<BonusAdd />} />
				<Route path="/bonus/edit/:pageid" element={<BonusEdit />} />

				{/* clients pages routes */}
				<Route path="/clients" element={<ClientsList />} />
				<Route path="/clients/:fieldName/:fieldValue" element={<ClientsList />} />
				<Route path="/clients/index/:fieldName/:fieldValue" element={<ClientsList />} />
				<Route path="/clients/view/:pageid" element={<ClientsView />} />
				<Route path="/clients/add" element={<ClientsAdd />} />
				<Route path="/clients/edit/:pageid" element={<ClientsEdit />} />

				{/* days pages routes */}
				<Route path="/days" element={<DaysList />} />
				<Route path="/days/:fieldName/:fieldValue" element={<DaysList />} />
				<Route path="/days/index/:fieldName/:fieldValue" element={<DaysList />} />
				<Route path="/days/view/:pageid" element={<DaysView />} />
				<Route path="/days/add" element={<DaysAdd />} />
				<Route path="/days/edit/:pageid" element={<DaysEdit />} />

				{/* daysoperators pages routes */}
				<Route path="/daysoperators" element={<DaysoperatorsList />} />
				<Route path="/daysoperators/:fieldName/:fieldValue" element={<DaysoperatorsList />} />
				<Route path="/daysoperators/index/:fieldName/:fieldValue" element={<DaysoperatorsList />} />
				<Route path="/daysoperators/view/:pageid" element={<DaysoperatorsView />} />
				<Route path="/daysoperators/add" element={<DaysoperatorsAdd />} />
				<Route path="/daysoperators/edit/:pageid" element={<DaysoperatorsEdit />} />

				{/* devices pages routes */}
				<Route path="/devices" element={<DevicesList />} />
				<Route path="/devices/:fieldName/:fieldValue" element={<DevicesList />} />
				<Route path="/devices/index/:fieldName/:fieldValue" element={<DevicesList />} />
				<Route path="/devices/add" element={<DevicesAdd />} />
				<Route path="/devices/edit/:pageid" element={<DevicesEdit />} />

				{/* feedback pages routes */}
				<Route path="/feedback" element={<FeedbackList />} />
				<Route path="/feedback/:fieldName/:fieldValue" element={<FeedbackList />} />
				<Route path="/feedback/index/:fieldName/:fieldValue" element={<FeedbackList />} />
				<Route path="/feedback/view/:pageid" element={<FeedbackView />} />
				<Route path="/feedback/add" element={<FeedbackAdd />} />
				<Route path="/feedback/edit/:pageid" element={<FeedbackEdit />} />

				{/* files pages routes */}
				<Route path="/files" element={<FilesList />} />
				<Route path="/files/:fieldName/:fieldValue" element={<FilesList />} />
				<Route path="/files/index/:fieldName/:fieldValue" element={<FilesList />} />
				<Route path="/files/view/:pageid" element={<FilesView />} />
				<Route path="/files/add" element={<FilesAdd />} />
				<Route path="/files/edit/:pageid" element={<FilesEdit />} />

				{/* filetypes pages routes */}
				<Route path="/filetypes" element={<FiletypesList />} />
				<Route path="/filetypes/:fieldName/:fieldValue" element={<FiletypesList />} />
				<Route path="/filetypes/index/:fieldName/:fieldValue" element={<FiletypesList />} />
				<Route path="/filetypes/add" element={<FiletypesAdd />} />
				<Route path="/filetypes/edit/:pageid" element={<FiletypesEdit />} />

				{/* guides pages routes */}
				<Route path="/guides" element={<GuidesList />} />
				<Route path="/guides/:fieldName/:fieldValue" element={<GuidesList />} />
				<Route path="/guides/index/:fieldName/:fieldValue" element={<GuidesList />} />
				<Route path="/guides/view/:pageid" element={<GuidesView />} />
				<Route path="/guides/add" element={<GuidesAdd />} />
				<Route path="/guides/edit/:pageid" element={<GuidesEdit />} />

				{/* hours pages routes */}
				<Route path="/hours" element={<HoursList />} />
				<Route path="/hours/:fieldName/:fieldValue" element={<HoursList />} />
				<Route path="/hours/index/:fieldName/:fieldValue" element={<HoursList />} />
				<Route path="/hours/view/:pageid" element={<HoursView />} />
				<Route path="/hours/add" element={<HoursAdd />} />
				<Route path="/hours/edit/:pageid" element={<HoursEdit />} />

				{/* hoursoperators pages routes */}
				<Route path="/hoursoperators" element={<HoursoperatorsList />} />
				<Route path="/hoursoperators/:fieldName/:fieldValue" element={<HoursoperatorsList />} />
				<Route path="/hoursoperators/index/:fieldName/:fieldValue" element={<HoursoperatorsList />} />
				<Route path="/hoursoperators/view/:pageid" element={<HoursoperatorsView />} />
				<Route path="/hoursoperators/add" element={<HoursoperatorsAdd />} />
				<Route path="/hoursoperators/edit/:pageid" element={<HoursoperatorsEdit />} />

				{/* notes pages routes */}
				<Route path="/notes" element={<NotesList />} />
				<Route path="/notes/:fieldName/:fieldValue" element={<NotesList />} />
				<Route path="/notes/index/:fieldName/:fieldValue" element={<NotesList />} />
				<Route path="/notes/add" element={<NotesAdd />} />
				<Route path="/notes/edit/:pageid" element={<NotesEdit />} />

				{/* operatordevices pages routes */}
				<Route path="/operatordevices" element={<OperatordevicesList />} />
				<Route path="/operatordevices/:fieldName/:fieldValue" element={<OperatordevicesList />} />
				<Route path="/operatordevices/index/:fieldName/:fieldValue" element={<OperatordevicesList />} />
				<Route path="/operatordevices/add" element={<OperatordevicesAdd />} />
				<Route path="/operatordevices/edit/:pageid" element={<OperatordevicesEdit />} />

				{/* operatordivinatorytools pages routes */}
				<Route path="/operatordivinatorytools" element={<OperatordivinatorytoolsList />} />
				<Route path="/operatordivinatorytools/:fieldName/:fieldValue" element={<OperatordivinatorytoolsList />} />
				<Route path="/operatordivinatorytools/index/:fieldName/:fieldValue" element={<OperatordivinatorytoolsList />} />
				<Route path="/operatordivinatorytools/view/:pageid" element={<OperatordivinatorytoolsView />} />
				<Route path="/operatordivinatorytools/add" element={<OperatordivinatorytoolsAdd />} />
				<Route path="/operatordivinatorytools/edit/:pageid" element={<OperatordivinatorytoolsEdit />} />

				{/* operators pages routes */}
				<Route path="/operators" element={<OperatorsList />} />
				<Route path="/operators/:fieldName/:fieldValue" element={<OperatorsList />} />
				<Route path="/operators/index/:fieldName/:fieldValue" element={<OperatorsList />} />
				<Route path="/operators/view/:pageid" element={<OperatorsView />} />
				<Route path="/operators/add" element={<OperatorsAdd />} />
				<Route path="/operators/edit/:pageid" element={<OperatorsEdit />} />
				<Route path="/operators/reclutamento" element={<OperatorsReclutamento />} />
				<Route path="/operators/reclutamento/:fieldName/:fieldValue" element={<OperatorsReclutamento />} />
				<Route path="/operators/editc/:pageid" element={<OperatorsEditc />} />
				<Route path="/operators/collaboratori" element={<OperatorsCollaboratori />} />
				<Route path="/operators/collaboratori/:fieldName/:fieldValue" element={<OperatorsCollaboratori />} />

				{/* operatorstatuses pages routes */}
				<Route path="/operatorstatuses" element={<OperatorstatusesList />} />
				<Route path="/operatorstatuses/:fieldName/:fieldValue" element={<OperatorstatusesList />} />
				<Route path="/operatorstatuses/index/:fieldName/:fieldValue" element={<OperatorstatusesList />} />
				<Route path="/operatorstatuses/add" element={<OperatorstatusesAdd />} />
				<Route path="/operatorstatuses/edit/:pageid" element={<OperatorstatusesEdit />} />

				{/* permissions pages routes */}
				<Route path="/permissions" element={<PermissionsList />} />
				<Route path="/permissions/:fieldName/:fieldValue" element={<PermissionsList />} />
				<Route path="/permissions/index/:fieldName/:fieldValue" element={<PermissionsList />} />
				<Route path="/permissions/view/:pageid" element={<PermissionsView />} />
				<Route path="/permissions/add" element={<PermissionsAdd />} />
				<Route path="/permissions/edit/:pageid" element={<PermissionsEdit />} />

				{/* qualifications pages routes */}
				<Route path="/qualifications" element={<QualificationsList />} />
				<Route path="/qualifications/:fieldName/:fieldValue" element={<QualificationsList />} />
				<Route path="/qualifications/index/:fieldName/:fieldValue" element={<QualificationsList />} />
				<Route path="/qualifications/view/:pageid" element={<QualificationsView />} />
				<Route path="/qualifications/add" element={<QualificationsAdd />} />
				<Route path="/qualifications/edit/:pageid" element={<QualificationsEdit />} />

				{/* roles pages routes */}
				<Route path="/roles" element={<RolesList />} />
				<Route path="/roles/:fieldName/:fieldValue" element={<RolesList />} />
				<Route path="/roles/index/:fieldName/:fieldValue" element={<RolesList />} />
				<Route path="/roles/view/:pageid" element={<RolesView />} />
				<Route path="/roles/add" element={<RolesAdd />} />
				<Route path="/roles/edit/:pageid" element={<RolesEdit />} />

				{/* support pages routes */}
				<Route path="/support" element={<SupportList />} />
				<Route path="/support/:fieldName/:fieldValue" element={<SupportList />} />
				<Route path="/support/index/:fieldName/:fieldValue" element={<SupportList />} />
				<Route path="/support/add" element={<SupportAdd />} />
				<Route path="/support/edit/:pageid" element={<SupportEdit />} />

				{/* typesdocuments pages routes */}
				<Route path="/typesdocuments" element={<TypesdocumentsList />} />
				<Route path="/typesdocuments/:fieldName/:fieldValue" element={<TypesdocumentsList />} />
				<Route path="/typesdocuments/index/:fieldName/:fieldValue" element={<TypesdocumentsList />} />
				<Route path="/typesdocuments/view/:pageid" element={<TypesdocumentsView />} />
				<Route path="/typesdocuments/add" element={<TypesdocumentsAdd />} />
				<Route path="/typesdocuments/edit/:pageid" element={<TypesdocumentsEdit />} />
				<Route path="/amministratore" element={<AmministratorePage />} />
				<Route path="/utente" element={<UtentePage />} />
				<Route path="/account/*" element={<AccountPages />} />
			</Route>
			</Route>
			<Route exact element={<IndexLayout />}>
				<Route path="/" element={<DefaultPage />} />
				<Route path="/*" element={<IndexPages />} />
				<Route path="/error/*" element={<ErrorPages />} />
			</Route>
		</Routes>
	);
}
export default App;
