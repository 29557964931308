/**
 * @Category React Hook function
 * Provide single source to manage application static menus items
 * 
**/


export default function useMenus() {
    
    
    return {
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/operators/user_role_id/3",
    "label": "Collaboratori",
    "icon": "pi pi-user-plus",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/controllo qualità",
    "label": "Controllo Qualità",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/clients",
    "label": "Clienti",
    "icon": "pi pi-users",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/operators/reclutamento",
    "label": "Candidati",
    "icon": "pi pi-users",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/operators/status_operator/4",
    "label": "Contattati",
    "icon": "pi pi-phone",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/operators",
    "label": "Operatori",
    "icon": "pi pi-user",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/bonus",
    "label": "Bonus",
    "icon": "pi pi-angle-double-up",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/appointments/agenda",
    "label": "Agenda",
    "icon": "pi pi-calendar",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/guides",
    "label": "Guide",
    "icon": "pi pi-book",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/support",
    "label": "Supporto",
    "icon": "pi pi-comments",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/appointments/agendareclutamento",
    "label": "Agenda Reclutamento",
    "icon": "pi pi-calendar-plus",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/home",
    "label": "Impostazioni",
    "icon": "pi pi-cog",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/operatorstatuses",
        "label": "Stati Operatori",
        "icon": "",
        "iconcolor": "",
        "target": "",
      },
      {
        "to": "/devices",
        "label": "Dispositivi",
        "icon": "",
        "iconcolor": "",
        "target": "",
      },
      {
        "to": "/alias",
        "label": "Alias",
        "icon": "",
        "iconcolor": "",
        "target": "",
      },
      {
        "to": "/filetypes",
        "label": "Tipo File",
        "icon": "",
        "iconcolor": "",
        "target": "",
      }
    ]
  }
],
	gender: [    
{value: "Uomo", label: "Uomo"},
	{value: "Donna", label: "Donna"},
	{value: "Altro", label: "Altro"}
    ],
	used: [    
{value: "0", label: "No"},
	{value: "1", label: "Si"}
    ],
	valueType: [    
{value: "1", label: "Percentuale"},
	{value: "2", label: "Euro"}
    ],
	gender2: [{"label":"Uomo","value":"Uomo"},{"label":"Donna","value":"Donna"},{"label":"Altro","value":"Altro"}],
        exportFormats: {
            print: {
                label: 'Print',
                icon: 'pi pi-print',
                type: 'print',
                ext: '',
            },
            pdf: {
                label: 'Pdf',
                icon: 'pi pi-file-pdf',
                type: 'pdf',
                ext: 'pdf',
            },
            excel: {
                label: 'Excel',
                icon: 'pi pi-file-excel',
                type: 'excel',
                ext: 'xlsx',
            },
            csv: {
                label: 'Csv',
                icon: 'pi pi-table',
                type: 'csv',
                ext: 'csv',
            },
        },
    }
}